<template>
  <div class="admin-home">
    <nav
      class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow"
    >
      <a
        class="navbar-brand col-md-3 col-lg-2 mr-0 px-3"
        style="font-size: 1rem"
        @click="goPage('Main')"
        >中山市恒丰利织造有限公司</a
      >
      <button
        class="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap" v-if="isLogin">
          <span style="color: aliceblue">{{ cust_name }}</span>
        </li>
      </ul>
      <ul class="nav px-3 navbar-right" style="line-height: 43px">
        <li class="nav-item text-nowrap" v-if="isLogin">
          <a style="color: aliceblue; margin-right: 3rem"
            >欢迎，{{ username }}</a
          >
        </li>
        <li class="nav-item text-nowrap" v-if="isLogin">
          <a class="logout" @click="logout"
            >退出
            <i class="fa fa-sign-out" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <nav
          id="sidebarMenu"
          class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
        >
          <div class="sidebar-sticky pt-3">
            <!-- <ul class="nav flex-column">
              <li class="nav-item">
                <a class="nav-link active">
                  <i class="fa fa-home fa-lg" aria-hidden="true" style="margin-right: 16px;"></i>
                  首页
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" @click="goPage('OrderInfo')">
                  <i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i>创建订单
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="goPage('OrderQuery')">
                  <i class="fa fa-search fa-lg" aria-hidden="true"></i>订单查询
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="goPage('Customer')">
                  <i class="fa fa-user fa-lg" aria-hidden="true"></i>客户
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="goPage('ReportForms')"
                  ><i class="fa fa-list" aria-hidden="true" style="margin-right: 16px;"></i> 报表
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="goPage('SystemSet')">
                  <i class="fa fa-cog fa-lg" aria-hidden="true"></i>系统设置
                </a>
              </li>
            </ul> -->

            <!-- <el-col>
              <el-menu
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
                :unique-opened="true"
                active-text-color="#f5533d"
                text-color="#000000"
              >
                <div v-for="(item, index) in navList" :key="index">
                  <el-submenu :index="item.id + ''" :data-index="index">
                    <div slot="title">
                      <i :class="item.icon"></i>
                      <span>{{ item.list_name }}</span>
                    </div>
                    <div v-for="(item, indexs) in navInfo" :key="indexs">
                      <el-menu-item
                        :index="item.id_child"
                        :data-index="index"
                        @click="goNavPage(item)"
                        >{{ item.list_name }}</el-menu-item
                      >
                    </div>
                  </el-submenu>
                </div>
              </el-menu>
            </el-col> -->

            <el-col>
              <el-menu
                :default-active="$route.name"
                router
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
                active-text-color="#f5533d"
                text-color="#000000"
                :collapse-transition="false"
              >
                <el-submenu index="1">
                  <template slot="title">
                    <i
                      :class="navList[0] && navList[0].icon"
                      aria-hidden="true"
                    ></i>
                    <span>{{ navList[0] && navList[0].list_name }}</span>
                  </template>
                  <el-menu-item-group v-for="(item, i) in navInfo[0]" :key="i">
                    <el-menu-item
                      :index="item.url"
                      @click="goNavPage(item)"
                      >{{ item.list_name }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
                <el-submenu index="2">
                  <template slot="title">
                    <i
                      :class="navList[1] && navList[1].icon"
                      aria-hidden="true"
                    ></i>
                    <span>{{ navList[1] && navList[1].list_name }}</span>
                  </template>
                  <el-menu-item-group v-for="(item, i) in navInfo[1]" :key="i">
                    <el-menu-item
                      :index="item.url"
                      @click="goNavPage(item)"
                      >{{ item.list_name }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
                <el-submenu index="3">
                  <template slot="title">
                    <i
                      :class="navList[2] && navList[2].icon"
                      aria-hidden="true"
                    ></i>
                    <span>{{ navList[2] && navList[2].list_name }}</span>
                  </template>
                  <el-menu-item-group v-for="(item, i) in navInfo[2]" :key="i">
                    <el-menu-item
                      :index="item.url"
                      @click="goNavPage(item)"
                      >{{ item.list_name }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
                <el-submenu index="4" v-if="cust_id == '261'">
                  <template slot="title">
                    <i
                      :class="navList[3] && navList[3].icon"
                      aria-hidden="true"
                    ></i>
                    <span>{{ navList[3] && navList[3].list_name }}</span>
                  </template>
                  <el-menu-item-group v-for="(item, i) in navInfo[3]" :key="i">
                    <el-menu-item
                      :index="item.url"
                      @click="goNavPage(item)"
                      >{{ item.list_name }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
                <el-submenu index="5" v-if="cust_id == '261'">
                  <template slot="title">
                    <i
                      :class="navList[4] && navList[4].icon"
                      aria-hidden="true"
                    ></i>
                    <span>{{ navList[4] && navList[4].list_name }}</span>
                  </template>
                  <el-menu-item-group v-for="(item, i) in navInfo[4]" :key="i">
                    <el-menu-item
                      :index="item.url"
                      @click="goNavPage(item)"
                      >{{ item.list_name }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </el-col>
          </div>
        </nav>
        <main
          role="main"
          class="col-md-9 ml-sm-auto col-lg-10 px-md-4"
          id="main"
        >
          <!-- 二级路由 -->
          <router-view></router-view>
        </main>
      </div>
    </div>
    <footer class="py-5 bg-dark fixed-bottom">
      <div class="container">
        <p class="m-0 text-center text-white">
          Copyright &copy; 恒丰利织造有限公司 2021
          <a class="icp" @click="goBean">粤ICP备17115312号</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import "../assets/css/adminhome.less";
import "../assets/css/dashboard.less";

export default {
  data() {
    return {
      username: "",
      cust_id: "",
      cust_name: "",
      isLogin: false,
      url: "http://beian.miit.gov.cn/",
      navList: [],
      navLength: "",
      navInfo: [],
      navListId: [],
      // activeIndex: "",
      path: "",
    };
  },

  created() {
    this.getUserInfo();
    this.getNavList();
    // this.getNavInfo();
    if (sessionStorage.getItem("user")) {
      this.isLogin = true;
    }
  },

  mounted() {

  },

  methods: {
    // 获取用户信息
    getUserInfo() {
      this.username = sessionStorage.getItem("user");
      this.cust_id = sessionStorage.getItem("cust_id");
      this.cust_name = sessionStorage.getItem("cust_name");
    },

    goPage(name) {
      this.$router.push({ name });
    },

    goNavPage(item) {
      // this.activeIndex = item.id_child;
      this.$router.push({ name: item.url });
    },

    // 退出登录
    logout() {
      if (this.cust_id == "261") {
        this.$router.push({ name: "CompanyLogin" });
      } else {
        this.$router.push({ name: "Login" });
      }
      sessionStorage.clear();
    },

    goBean() {
      var r = confirm("您将要访问：http://beian.miit.gov.cn/，是否继续？");
      if (r == true) {
        window.location.href = this.url;
      }
    },

    getNavList() {
      this.axios({
        methods: "GET",
        url: "http://wx.hengfeng-zl.cn/dingTalk/GetNavList.ashx",
        params: {
          ctype: "getNavList",
          cust_id: this.cust_id,
        },
      })
        .then((res) => {
          this.navList = res.data.data;
          this.navLength = this.navList.length;

          for (var i = 1; i <= this.navLength; i++) {
            this.axios({
              methods: "GET",
              url: "http://wx.hengfeng-zl.cn/dingTalk/GetNavList.ashx",
              params: {
                ctype: "getNavInfo",
                cust_id: this.cust_id,
                navId: i,
              },
            })
              .then((res) => {
                this.navInfo.push(res.data.data);

                if (this.navInfo.length == this.navLength) {
                  // 冒泡排序
                  for (var j = 0; j < this.navInfo.length - 1; j++) {
                    for (var k = 0; k < this.navInfo.length - 1 - j; k++) {
                      if (this.navInfo[k][0].fid > this.navInfo[k + 1][0].fid) {
                        var swap = this.navInfo[k];
                        this.navInfo[k] = this.navInfo[k + 1];
                        this.navInfo[k + 1] = swap;
                      }
                    }
                  }
                  return this.navInfo;
                }
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {});
    },

    handleOpen(key, keyPath) {},

    handleClose(key, keyPath) {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-menu-item:focus,
.el-menu-item:hover,
.el-submenu__title:focus {
  background-color: #fef2f0 !important;
  color: #f5533d !important;
}

::v-deep .el-submenu__title:hover {
    background-color: #fef2f0 !important;
}

::v-deep .el-menu-item.is-active {
  color: #f5533d !important;
  background-color: #fef2f0 !important;
}
::v-deep .el-submenu__title i:not(.el-icon-arrow-down) {
  margin-right: 22px !important;
  color: #f5533d !important;
}
::v-deep .el-submenu__title {
  font-size: 16px !important;
}
::v-deep .el-submenu .el-menu-item {
  font-size: 16px !important;
  padding: 0 0 0 60px !important;
}
</style>
